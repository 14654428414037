import * as React from "react";
import siteSettings from "../constants/siteSettings";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Link } from "gatsby";

interface BreadCrumbsProps {
  visitPageTitle: string;
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ visitPageTitle }) => {
  return (
    <div className="breadcrumbs">
      <div className="containerb">
        <div className="container">
          <ol>
            <li>
              <Link to="/">
                <i className="fa-solid fa-house"></i> Home
              </Link>
            </li>
            <li>{visitPageTitle}</li>
          </ol>
          <h2>{visitPageTitle}</h2>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumbs;
